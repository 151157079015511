import { useEffect, useState, useMemo } from 'react'
import Decimal from 'decimal.js'
import cn from 'classnames'
import Modal from 'react-modal'
import { Close } from '@components/icons/next'
import { useRouter } from 'next/router'
import debounce from 'lodash.debounce'
import { TagIcon } from '@heroicons/react/24/solid'
import { useUI } from '@components/ui/context'
import { useCommerce } from '@commerce/index'
import { Button, Picture, Text } from '@components/ui'
import { BRAND } from '@shopify/const'
import usePrice, { formatPrice } from '@shopify/product/use-price'
import { useUpdateItem, useRemoveItem } from 'lib/shopify/api/cart'
import { ProductQuantity, ProductPrice } from '@components/product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID, getThemeUrl } from '@lib/utils/tools'
import BannerLink from '@components/common/BannerLink'

import Delete from './Delete'
import Code from './Code'

import s from './CartItem.module.css'

const placeholderImg = '/product-img-placeholder.svg'

const Tags = ({ giveawayItem, tag: c_tag, variant, shopCommon, className }) => {
  const tag = useMemo(() => {
    if (giveawayItem) return shopCommon?.tag_freeGift || 'Free Gift'
    return c_tag
  }, [c_tag, giveawayItem])

  const itemTag = useMemo(() => {
    if (giveawayItem) return true
    // return tag in (shopCommon?.tagToDescription || {})
  }, [])

  if (!itemTag) return ''

  return (
    <div className={cn('inline-block', className)}>
      {tag?.link?.to ? (
        <div className="flex items-center justify-between pb-2 pt-2 text-[0.8rem] leading-normal md:text-[3vw]">
          <div className="flex items-center">
            {tag?.label && (
              <Text
                html={tag?.label}
                className="flex-shrink-0 rounded border border-anker-color px-1 text-anker-color"
                variant="paragraph"
              />
            )}
            {tag?.description && (
              <Text
                html={tag?.description}
                className="h-fit"
                variant="paragraph"
              />
            )}
          </div>
          <a
            className="text-anker-color"
            href={setRelativeLink({ link: tag?.link?.to })}
            onClick={() =>
              pageGTMEvent({
                event: 'uaEvent',
                eventCategory: 'Product Detail Page_' + variant?.sku,
                eventAction: 'special_offer',
                eventLabel: 'learn_more',
              })
            }
          >
            {tag?.link?.label}
          </a>
        </div>
      ) : (
        tag && (
          <Text
            variant="paragraph"
            className="w-fit flex-shrink-0 bg-[#DAEDFF] px-1 text-[0.8rem] font-semibold leading-normal text-[#0082FF] md:text-[3vw]"
            html={tag || ''}
          />
        )
      )}
    </div>
  )
}

const CartItem = ({
  item,
  mode,
  summerLevel,
  gradientLevel,
  bundleItem,
  currencyCode,
  shopCommon = {},
  shoppingInfo = {},
  mainProduct = '',
  discountPrice,
  modal = 'default',
  position,
  allSku,
  freegift_related,
  getBundleSize,
  quantityBreakDescription,
  removeItemCbk,
  quantityBreakDiscountData,
  children,
  ...rest
}) => {
  const { locale } = useRouter()
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  //根据sku限购需求，最大最小值从shop里配置的sku里获取
  const { shop } = useCommerce()
  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[item?.variant?.sku]
  const limitMin = orderLimit?.min !== undefined ? orderLimit?.min : 1
  item.quantity = item.quantity >= limitMin ? item.quantity : limitMin
  const [quantity, setQuantity] = useState(item?.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({
    item,
  })

  const [amount, baseAmount, totalDiscount] = useMemo(() => {
    const amount = Number(item?.totalAmount || item?.price)
    const baseAmount = Number(item?.subtotalAmount || item?.price)
    const totalDiscount = new Decimal(baseAmount).minus(new Decimal(amount))

    return [
      amount,
      baseAmount,
      totalDiscount > 0
        ? formatPrice({
            amount: Number(totalDiscount),
            currencyCode: currencyCode,
            locale,
          })
        : false,
    ]
  }, [item.totalAmount, item.subtotalAmount])
  const [errorTip, setErrorTip] = useState('')
  const errorHandle = (error, curQuantity) => {
    setErrorTip(error)
    setQuantity('')
    setTimeout(() => {
      setQuantity(curQuantity)
    }, 10)
    const errorTimer = setTimeout(() => {
      setErrorTip('')
      clearTimeout(errorTimer)
    }, 8000)
  }
  const { price, basePrice, free } = usePrice({
    amount: discountPrice && discountPrice > 0 ? Number(discountPrice) : amount,
    baseAmount: baseAmount,
    currencyCode,
  })

  const { metafields = {} } = item?.variant

  const handleChange = async (value) => {
    if (!bundleItem) setQuantity(Number(value))
    if (item?.quantity === value || errorTip) return false
    try {
      await updateItem({
        quantity: Number(value),
        customAttributes: item?.customAttributes || [],
      })
      setErrorTip('')
    } catch (err) {
      console.warn('change updateCart err', err)
      errorHandle(err?.message || 'updateCart err', item.quantity)
    }
  }

  const increaseQuantity = async (n = 1) => {
    const curQuantity = item.quantity
    if (bundleItem) {
      const val = Number(item.quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('bundle updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    } else {
      const val = Number(quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    }
    if (rest?.form === 'CartSidebarView') {
      pageGTMEvent({
        // GA4
        event: 'ga4Event',
        event_name: n > 0 ? 'add_to_cart' : 'remove_from_cart',
        event_parameters: {
          page_group: document.location.href,
          position:
            n > 0 ? 'Shopping Cart Sidebar' : 'Shopping Cart Sidebar_Minus',
          currency: currencyCode,
          value: price,
          items: [
            {
              item_id: item?.variant?.sku,
              item_name: item.name,
              item_brand: BRAND,
              item_category: item.productType,
              item_variant: item.variant.name,
              price: price,
              quantity: item.quantity,
            },
          ],
        },
      })
      n > 0 &&
        pageGTMEvent({
          event: 'Add to Cart',
          page_group: document.location.href,
          position: 'Shopping Cart Sidebar',
          ecommerce: {
            currency: currencyCode,
            value: price,
            items: [
              {
                item_id: atobID(item.id),
                item_name: item.name,
                item_brand: BRAND,
                item_variant: item.variant.name,
                item_category: item.productType,
                price: price,
                quantity: item.quantity,
              },
            ],
          },
        })
    }
  }

  const handleRemove = async () => {
    const id = [item?.id]
    if (freegift_related?.length)
      id.push(...freegift_related?.map((item) => item?.id))
    setRemoving(true)
    try {
      await removeItem({ id })
      removeItemCbk && removeItemCbk()
      console.log('rest?.form', rest?.form)

      if (rest?.form === 'CartSidebarView') {
        pageGTMEvent({
          event: 'eeEvent',
          eventCategory: 'EnhancedEcommerce',
          eventAction: 'Removed Product',
          nonInteraction: false,
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [
                {
                  id: item.variant.sku,
                  name: item.name,
                  price: price,
                  quantity: item.quantity,
                  variant: item?.variant?.name,
                  position: rest?.position,
                  brand: BRAND,
                  category: '',
                },
              ],
            },
          },
        })
        pageGTMEvent({
          // GA4
          event: 'ga4Event',
          event_name: 'remove_from_cart',
          event_parameters: {
            page_group: document.location.href,
            position: 'Shopping Cart Sidebar_delete',
            currency: currencyCode,
            value: price,
            items: [
              {
                item_id: item?.variant?.sku,
                item_name: item.name,
                item_brand: BRAND,
                item_variant: item.variant.name,
                price: price,
                quantity: item.quantity,
              },
            ],
          },
        })
      }
    } catch (error) {
      setRemoving(false)
    }
  }

  const options = useMemo(() => {
    return item.options
  }, [item.options, item?.variant?.options, modal])

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.quantity])

  const handleNameClick = () => {
    closeSidebarIfPresent()
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_title',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
  }

  const handleImgClick = () => {
    closeSidebarIfPresent()

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_image',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
  }

  const link =
    `${locale === 'us' ? '' : '/' + locale}/products${item.path}` +
    `?variant=${atobID(
      item?.variant?.id || product?.variants[0]?.id
    )}&?ref=cart_pop_up`

  const giveawayItem = useMemo(
    () =>
      // 判断是否是赠品
      Number(item?.totalAmount) === 0 &&
      item?.customAttributes?.find(
        (attribute) =>
          attribute.key === '_freegift_related_handlesku' ||
          attribute.key === '_giveaway' ||
          attribute.key === '_giveaway_summerCampaignDiscount' ||
          attribute.key === '_giveaway_gradient_gifts'
      ),
    [item]
  )

  const itemTag = useMemo(
    () =>
      item.product.tags?.find(
        (tag) => tag in (shopCommon?.tagToDescription || {})
      ),
    [item]
  )
  const max = useMemo(() => {
    if (item.variant?.currentlyNotInStock) return 999
    return item.variant?.quantityAvailable > 0
      ? item.variant?.quantityAvailable
      : 1
  }, [item])

  const currentLevel = useMemo(() => {
    if (
      item?.customAttributes?.find(
        (attribute) => attribute.key === '_giveaway_gradient_gifts'
      ) &&
      gradientLevel?.breakpoint
    ) {
      return formatPrice({
        amount: new Decimal(gradientLevel?.breakpoint).toNumber(),
        currencyCode,
        locale,
        minimumFractionDigits: 0,
      })
    }

    if (
      item?.customAttributes?.find(
        (attribute) => attribute.key === '_giveaway_summerCampaignDiscount'
      ) &&
      summerLevel?.breakpoint
    ) {
      return formatPrice({
        amount: new Decimal(summerLevel?.breakpoint).toNumber(),
        currencyCode,
        locale,
        minimumFractionDigits: 0,
      })
    }
  }, [item?.customAttributes])

  return (
    <li
      className={cn('border-b border-[#E8E8E8] last:border-none', {
        'border-none': giveawayItem,
        [s.giveawayItem]: giveawayItem,
      })}
      {...rest}
    >
      {giveawayItem && giveawayItem?.key !== '_freegift_related_handlesku' && (
        <Text
          html={(
            shopCommon?.freeGiftTip || 'Anker Free gift for orders over $price:'
          )?.replace('$price', `<span>${currentLevel || ''}</span>`)}
          className="mb-2 mt-5 text-sm font-semibold [&_span]:text-[#0082FF]"
        />
      )}
      <div
        className={cn(s.root, {
          'pointer-events-none opacity-50': removing,
          'bg-[#F7F8F9] !p-4': giveawayItem,
        })}
      >
        <div className="flex h-full w-full items-stretch justify-between space-x-2">
          <div className="flex w-full">
            {/* 产品图 */}
            <div className="relative flex-none mr-4">
              {!item?.variant?.availableForSale && (
                <ProductPrice
                  className={s.soldout}
                  styles={s}
                  free={free}
                  price={price}
                  basePrice={basePrice}
                  discountPrice={
                    totalDiscount &&
                    !giveawayItem &&
                    `${shopCommon?.save || ''} ${totalDiscount}`
                  }
                  availableForSale={item.variant?.availableForSale}
                  settings={shopCommon}
                  autoTestTag="Auto-10000424"
                />
              )}
              <Picture
                className={cn(s.productImage)}
                source={item.variant.image?.url || placeholderImg}
                alt={item.variant.image?.altText || 'Product Image'}
              />
              <BannerLink to={link} onClick={handleImgClick} />
            </div>
            {/* 产品信息 */}
            <div className="grid h-fit grow grid-flow-row gap-1 text-base">
              {/* 产品名 */}
              <div className="block relative">
                <Tags
                  className="mr-1"
                  tag={itemTag}
                  shopCommon={shopCommon}
                  variant={item?.variant}
                  giveawayItem={giveawayItem}
                />
                <a
                  href={link}
                  tag="Auto-10000421"
                  onClick={handleNameClick}
                  className={cn(s.productName)}
                  dangerouslySetInnerHTML={{
                    __html: `${item.name || item.product_title} ${
                      quantity > 1 && giveawayItem ? 'x' + quantity : ''
                    }`,
                  }}
                />
                {!item?.variant?.availableForSale && (
                  <button
                    aria-label="remove"
                    className="h-fit absolute right-0 top-0 rounded-full p-1 pr-0 hover:bg-gray-100"
                    onClick={() => setDeleteModal(true)}
                  >
                    <Delete className="h-5 w-5" />
                  </button>
                )}
              </div>
              {item?.product.tags?.length > 0 ? (
                <div className="flex gap-1">
                  {item?.product.tags?.map((tag, index) => {
                    return (
                      <Tags
                        key={'tag' + index}
                        tag={tag}
                        shopCommon={shopCommon}
                        variant={item?.variant}
                      />
                    )
                  })}
                </div>
              ) : (
                ''
              )}
              {/* 产品选项 */}
              {options && options.length > 0 && (
                <div className="text-sm font-medium text-[#999]">
                  {options
                    ?.map((option, i) => option?.value)
                    ?.filter((v) => v !== 'Default Title')
                    ?.join(' / ')}
                </div>
              )}

              {item.variant?.availableForSale && (
                <>
                  {/* 数量 controller */}
                  {modal === 'default' && !giveawayItem && (
                    <div className="mb-2 flex items-center justify-between">
                      <ProductQuantity
                        s={{ quantityBox: s.quantityBox }}
                        className={s.quantity}
                        value={quantity}
                        pId={item.variant.sku}
                        increase={(num) => increaseQuantity(num || 1)}
                        decrease={(num) => increaseQuantity(num || -1)}
                        detete={() => setDeleteModal(true)}
                        handleChange={debounce(handleChange, 500)}
                        availableForSale={item.variant.availableForSale}
                        currentlyNotInStock={item.variant.currentlyNotInStock}
                        max={max}
                        min={0}
                        settings={shopCommon}
                        form={rest?.form}
                      />
                      {modal === 'default' &&
                        (!giveawayItem || amount != 0) && (
                          <button
                            aria-label="remove"
                            className="h-fit rounded-full p-1 pr-0 hover:bg-gray-100"
                            onClick={() => setDeleteModal(true)}
                          >
                            <Delete className="h-5 w-5" />
                          </button>
                        )}
                    </div>
                  )}
                  {/* 默认购物车价格在图片和产品信息下方 */}
                  <div
                    className={cn(
                      'flex flex-wrap items-center justify-between text-lg md:text-base'
                    )}
                  >
                    <ProductPrice
                      className={s.productPrice}
                      styles={s}
                      free={free}
                      price={price}
                      basePrice={basePrice}
                      discountPrice={
                        totalDiscount &&
                        !giveawayItem &&
                        `${shopCommon?.save || ''} ${totalDiscount}`
                      }
                      availableForSale={item.variant?.availableForSale}
                      settings={shopCommon}
                      autoTestTag="Auto-10000424"
                    />
                    {giveawayItem && (
                      <button
                        aria-label="remove"
                        className="h-fit rounded-full p-1 pr-0 hover:bg-gray-100"
                        onClick={() => setDeleteModal(true)}
                      >
                        <Delete className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </>
              )}

              {metafields.cartInfo && (
                <div className={s.cartInfo}>
                  <Text html={metafields.cartInfo}></Text>
                </div>
              )}
            </div>
          </div>
        </div>

        {!giveawayItem && (
          <div className="mt-3 flex flex-col gap-3 bg-[#F7F8F9] py-3 empty:mt-0 empty:bg-none empty:py-0">
            {item?.discountAllocations &&
              item?.discountAllocations?.map((item, i) => {
                const amountFormat = formatPrice({
                  amount: item.amount,
                  currencyCode: currencyCode,
                  locale,
                })
                return (
                  <div
                    className="flex flex-wrap px-3 text-sm"
                    key={`order-${item?.code}-${i}`}
                  >
                    <div className="flex items-center">
                      <Code width={16} height={16} className="-mt-[2px] mr-1" />
                      <Text
                        variant="inline"
                        html={item.code}
                        className="align-middle font-medium leading-4 text-[#777777]"
                      />
                    </div>
                    <span className="ml-1 font-medium leading-4 text-[#777777]">
                      {` (-${amountFormat})`}
                    </span>
                  </div>
                )
              })}
          </div>
        )}

        {/* 满减折扣描述 */}
        {item.product?.tags?.includes(quantityBreakDiscountData?.productTag) &&
          !giveawayItem &&
          quantityBreakDescription}
        {orderLimits?.show && errorTip && (
          <Text html={errorTip} className={s.errorTip}></Text>
        )}

        {children && <div className="pt-2">{children}</div>}
      </div>
      <Modal
        isOpen={!!deleteModal}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Code Delete comfire"
        style={{
          overlay: {
            zIndex: '1000001',
          },
          content: {
            width: '100%',
            maxWidth: '500px',
            borderRadius: '0',
          },
        }}
      >
        <button
          className="absolute right-1 top-1 p-2"
          onClick={() => setDeleteModal(false)}
        >
          <Close width="20" height="20" />
        </button>
        <div className="px-10 py-8 pb-6">
          <Text
            className="text-xl font-medium leading-[1.3]"
            html={
              shoppingInfo?.deleteProductTitle ||
              'Are you sure you want to delete this product?'
            }
          />
          <div className="mt-4 flex items-center rounded-sm bg-[#F7F8F9] px-3 py-1 text-sm">
            <div className="relative flex-none self-center">
              <Picture
                className={cn(s.productImage)}
                source={item.variant.image?.url || placeholderImg}
                alt={item.variant.image?.altText || 'Product Image'}
              />
            </div>
            <div className="block">
              <div
                className={s.productName}
                dangerouslySetInnerHTML={{
                  __html: item.name || item.product_title,
                }}
              />
              {options && options.length > 0 && (
                <div className="">
                  {options?.map(
                    (option, i) =>
                      !option?.value?.includes('Default Title') && (
                        <div
                          key={`${item.id}-${option.name}`}
                          className="text-sm font-medium text-[#999]"
                        >
                          {option.name}:
                          <span className="ml-1 overflow-hidden">
                            {option.value}
                          </span>
                          {i === options.length - 1 ? (
                            ''
                          ) : (
                            <span className="mr-3" />
                          )}
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-5 flex justify-center gap-5">
            <Button
              variant="secondary"
              className="w-[50%] min-w-[100px] text-base !rounded-none px-5 font-semibold"
              onClick={() => setDeleteModal(false)}
            >
              {shopCommon?.cancel || 'Cancel'}
            </Button>
            <Button
              tag="Auto-10000425"
              className="w-[50%] min-w-[100px] !rounded-none font-semibold"
              onClick={() => {
                handleRemove()
                setDeleteModal(false)
              }}
            >
              {shopCommon?.confirm || 'Confirm'}
            </Button>
          </div>
        </div>
      </Modal>
    </li>
  )
}

export default CartItem
